<template>
    <div class="formFooter">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'formFooter'
    }
</script>

<style lang="less" scoped>
    .formFooter {
        position: absolute;
        z-index: 500;
        bottom: 0;
        left: 0;
        box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.20);
        height: 50px;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        width: 100%;
        align-items: center;
        background: #fff;
        .rs-button {
            margin-right: 14px;
        }
        z-index: 10000000;
    }
</style>