var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-form",
                {
                  ref: "formData",
                  staticClass: "W7 fixItemWidth",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "120px",
                    "show-message": false,
                    size: "small",
                  },
                },
                [
                  _c(
                    "rs-col",
                    [
                      _c(
                        "rs-form-item",
                        { attrs: { label: "项目名称", prop: "mallCode" } },
                        [
                          _c("rs-select-mall", {
                            ref: "mallRef",
                            attrs: {
                              host: "/api/user",
                              clearable: false,
                              mustFlag: false,
                            },
                            on: { change: _vm.changeMall },
                            model: {
                              value: _vm.formData.mallCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "mallCode", $$v)
                              },
                              expression: "formData.mallCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rs-col",
                    [
                      _c(
                        "rs-form-item",
                        { attrs: { label: "小票抬头内容", prop: "groupName" } },
                        [
                          _c("rs-input", {
                            model: {
                              value: _vm.formData.header,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "header", $$v)
                              },
                              expression: "formData.header",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rs-col",
                    [
                      _c(
                        "rs-form-item",
                        { attrs: { label: "小票页脚内容", prop: "groupName" } },
                        [
                          _c("rs-input", {
                            model: {
                              value: _vm.formData.footerContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "footerContent", $$v)
                              },
                              expression: "formData.footerContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rs-col",
                    [
                      _c(
                        "rs-form-item",
                        {
                          attrs: { label: "小票页脚二维码", prop: "groupName" },
                        },
                        [
                          _c("rs-input", {
                            model: {
                              value: _vm.formData.footerCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "footerCode", $$v)
                              },
                              expression: "formData.footerCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form-footer",
        [
          _c(
            "rs-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.confimLoading,
                size: "small",
              },
              on: { click: _vm.confimAdd },
            },
            [_vm._v("保 存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }