<template>
    <div>
        <div class="content-container">
            <rs-row>
                <rs-form
                        :model="formData"
                        :rules="rules"
                        ref="formData"
                        label-width="120px"
                        :show-message="false"
                        class="W7 fixItemWidth"
                        size="small"
                >
                    <rs-col>
                        <rs-form-item label="项目名称" prop="mallCode">
                            <rs-select-mall
                                    :host="'/api/user'"
                                    v-model="formData.mallCode"
                                    :clearable="false"
                                    :mustFlag="false"
                                    ref="mallRef"
                                    @change="changeMall"
                            ></rs-select-mall>
                        </rs-form-item>
                    </rs-col>
                    <rs-col>
                        <rs-form-item label="小票抬头内容" prop="groupName">
                            <rs-input v-model="formData.header"></rs-input>
                        </rs-form-item>
                    </rs-col>
                    <rs-col>
                        <rs-form-item label="小票页脚内容" prop="groupName">
                            <rs-input v-model="formData.footerContent"></rs-input>
                        </rs-form-item>
                    </rs-col>
                    <rs-col>
                        <rs-form-item label="小票页脚二维码" prop="groupName">
                            <rs-input v-model="formData.footerCode"></rs-input>
                        </rs-form-item>
                    </rs-col>

                </rs-form>
            </rs-row>
        </div>
        <form-footer>
            <rs-button
                    type="primary"
                    :loading="confimLoading"
                    size="small"
                    @click="confimAdd"
            >保 存</rs-button>
        </form-footer>
    </div>

</template>

<script>
    import formFooter from "@/components/formFooter";
    import Api from '@/api/ticket'
    export default {
        name: "ticketSet.vue",
        data(){
            return {
                formData:{mallCode:''},
                rules:{
                    mallCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
                },
                confimLoading:false
            }
        },
        components: { formFooter },
        methods:{
            changeMall(val){
                Api.getDetail({
                    mallCode:val.mallCode
                }).then(res => {
                    this.formData = res.data.data?res.data.data:{mallCode:this.formData.mallCode}
                })

            },
            confimAdd(){
                this.$refs['formData'].validate((valid) => {
                    if (valid) {
                        let params = {
                            ...this.formData
                        }
                        Api.save(params).then(res => {
                            this.$message.success('保存成功')
                        })
                    } else {

                    }
                })
            }
        }
    }
</script>

<style scoped>
.content-container{
    padding:20px;
}
.W7{
    width:70%;
}
</style>