import Http from '../utils/axios'
const rt = '/api'
const pay = 'pay'
export default {
    save(params) { //保存小票信息
        return Http.post(`${rt}/${pay}/e/ticket/insertOrUpdate`, params, true)
    },
    getDetail(params) { //获取小票详情
        return Http.get(`${rt}/${pay}/e/ticket/get`, params, true)
    },
}
